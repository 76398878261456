.card .app-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    /* width: 40%; */
  }
  
  .app-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: rgba(245, 245, 245);    
  }

  .disabled-link {
    pointer-events: none;
    opacity: 0.4;
  }